.map{
  position: absolute;
  z-index: 0;
  width: 100%;
  height: $frameHight;

  overflow: hidden;

  .map-location{
    position: absolute;
    z-index: 2;

    margin-top: 10px;
    margin-left: 10px;
    padding:10px;


    display: grid;
    align-items: center;
    justify-content: center;

    @media screen and (max-height: $maxheight){
      //width: 130px;
    }
    
    background: rgba(243, 235, 207, 0.63);
    backdrop-filter: blur(170px);
    border-radius: 10px;

    .map-location-content{
      
      p{
        padding: 0px;
        margin: 0px;
        text-transform: uppercase;
        font-family: IBM Plex Mono Light;
        text-transform: uppercase;
        color: #000000;
  
        @media screen and (max-height: $maxheight){
          font-size: 12px;
        }
      }
      .map-location-address{
        margin-top:-3px;
      }
    }

   
  }
  .map-design{
    width: 5000px;
    height: 1934px;
    
  }

}
