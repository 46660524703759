.carousel-item{
  width: 100%;
  height: 25vh;


  display: grid;
  grid-template-rows: 60% 40%;

  
  @media screen and (max-height: $maxheight){
    height: 27vh;
    grid-template-rows: 60% 40%;
  }

  border-radius: $borderRound $borderRound 0px 0px;

  .carousel-item-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $borderRound;
  }

  .carousel-item-gradient{
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-radius: $borderRound;
  }


  .carousel-item-footer{
    display: grid;
    grid-template-columns: 80% 20%;

    align-items: center;
  }

  .title{
    width: 90%;
    margin: 0px 0px 0px 0px;
    font-family: Bookish-Off-White;
    font-size: 20px;
    font-kerning:none;

    @media screen and (max-height: $maxheight){
      font-size: 18px;
    }
  }

  .description{
    width: 90%;
    margin: 5px 0px 0px 0px;
    font-family: IBM Plex Mono Light;
    font-size: 14px;
    text-transform: uppercase;

    @media screen and (max-height: $maxheight){
      font-size: 12px;
    }
  }
  .times{
    text-transform: uppercase;
    margin: -3px 0px 0px 0px;
    font-family: IBM Plex Mono Light;
    font-size: 14px;

    @media screen and (max-height: $maxheight){
      font-size: 12px;
    }
  }

  .carousel-item-content{
    margin-top: 5%;
  }

  .bt-info{
    width: 50px;
    align-self: center;
    justify-self: center;


    @media screen and (max-height: $maxheight){
      width: 45px;
    }
  }
  

}