@font-face {
  font-family: 'Bookish-Off-White';
  src: url('../assets/fonts/bookish-off-white-regular.ttf');
}


@font-face {
  font-family: 'IBM Plex Mono Light';
  src: url('../assets/fonts/IBMPlexMono-Regular.ttf');
}


$maxheight: 700px;
$frameHight: 90vh;
$frameTopMargin: 5vh;
$borderRound: 10px;


