.carousel{
  position: absolute;
  z-index: 3;

  width: 100%;
  height: $frameHight;

  @media screen and (min-width: 1025px){
    width: 400px;
  }

  .content{
    position: absolute;
    bottom: 0px;
    //margin-top: 45vh;
    height: 40vh;
    width: 100%;

    @media screen and (max-height: $maxheight){    
      height: 40vh;
    }

    @media screen and (min-width: 1025px){
      border-radius: 10px;
    }

    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(20px);

    display: grid;
    grid-template-rows: 20% 80%;

    font-family: IBM Plex Mono Light;

    .carousel-content-header{
      display: grid;
      grid-template-columns: 80% 20%;

      @media screen and (max-height: $maxheight){
        font-size: 12px;
      }

      .carousel-content-header-title{
        margin-left: 20px;
      }
      .carousel-content-header-amount{
        justify-self: right;
        margin-right: 20px;
      }
    }
    .carousel-container{


      width: 100%;
      height: 100%;
    }

    .arrow-container{
      position: absolute;
      top: 40%;
      left: 7.5%;

      width: 85%;

      display: grid;
      grid-template-columns: 50% 50%;

      .arrow{
        width: 25px;
        
      }
      .arrow-left{
        @extend .arrow;
        justify-self: left;
        

      }
      .arrow-right{
        @extend .arrow;
        transform: rotate(180deg);
        justify-self: right;
      }
    }

  }
}
