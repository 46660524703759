.app {
  width: 100%;
  height: $frameHight;
  margin-top: $frameTopMargin;
  background:#D2C29C;
  overflow: hidden;
  

  .load-image{
    width: 0px;
    height: 0px;
  }
}
