.map-marker{
  position: absolute;

  
  min-width: 120px;
  max-width: 400px;
  height: 50px;
  

  
  font-family: Bookish-Off-White;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #0023F5;

  display: grid;
  grid-template-columns: 20% 80%;
  justify-items: center;
  align-items: center;
  
  
  .marker-icon{
    width: 20px;
  }
  .marker-name{
    
    white-space: nowrap;
    justify-self: left;
    margin-left: 5px;

    background: #d2c39b;
    border-radius: 30px;
                        
  }
  

}
