.overlay-timer-marker{
  width: 100%;
  height: 50%;
  background:#666666 ;
  border-radius: 2px;

  .bar{
    width: 50%;
    height: 100%;
    background:white ;
    border-radius: 2px;
  }
}