.overlay{

  position: absolute;
  z-index: 3;
  overflow: hidden;

  width: 100%;
  height: $frameHight;

  background: black;

  .loader{
    width:100%;
    height:100%;
    position:absolute;
    z-index:10;
    display:grid;
    justify-items:center;
    align-items:center;
    pointer-events: none;
  }


  .overlay-hit-area{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
  }

  .menu{
    position: absolute;
    width: 100%;
    height: 10vh;
    z-index: 3;

    display: grid;
    grid-template-rows: 50% 50%;

    .bt-exit{
      width: 20px;
      justify-self: right;
      margin-right: 5%;
      //opacity: 30%;
    }
  }

  .stories-image{
    width: 100%;
  
  }

  .buffer-image{
    position:absolute;
    z-index:0;
    width: 100%;

  }

  .video-wrapper{
    width: 100%;
  }

  video{
    //object-fit: fill;
  }

}